<script>
import NavbarElement from '@/components/NavbarElement.vue';
import FooterElement from '@/components/FooterElement.vue';
import {computed, reactive} from "vue";
import {useHead} from "@vueuse/head";

export default {
  setup () {
    const siteData = reactive({
      title: 'Retractable roof',
      description: 'LITRA Retractable Roof solve problems of\n' +
          'adverse weather conditions for residential and\n' +
          'commercial applications such as restaurants, rooftop\n' +
          'enclosures, hotels, sport facilities, thermal complex and\n' +
          'more.',
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'descritpion',
          content: computed(() => siteData.description),
        }
      ]
    })
  },
  components: {
    FooterElement,
    NavbarElement
  }
}
</script>

<template>
  <NavbarElement />
  <RouterView />
	<!-- Footer start -->
	<footer-element></footer-element>
	<!-- Footer end -->
</template>

<style>

</style>
