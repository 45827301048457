<template>
    <!-- Portfolio section start -->
	<section id="portfolio-section" class="px-4 lg:px-8 py-16  relative">
        <!-- Text wrapper start -->
        <div class="flex gap-8 flex-col md:flex-row">
            <!-- Main text start -->
            <h1 class="uppercase text-5xl mb-4 font-semibold ">
                OUR WORK
            </h1>

            <!-- Main text end -->
        </div>
        <!-- Text wrapper end -->

        <!-- Images grid start -->
		<div class="grid-cols-2 md:grid-cols-5 grid mb-8 gap-4">
            <div class="grid grid-cols-1 md:hidden gap-4">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/1-3.jpg" alt="1" class="rounded-xl">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/Pergole1.jpg" alt="2" class="rounded-xl">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/Pergole2.png" alt="3" class="rounded-xl">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/3-6.jpg" alt="4" class="rounded-xl">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/11-3.jpg" alt="5" class="rounded-xl">
            </div>
            <div class="grid grid-cols-1 md:hidden gap-4">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/1-3.jpg" alt="6" class="rounded-xl">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/Pergole1.jpg" alt="7" class="rounded-xl">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/1-6.jpg" alt="8" class="rounded-xl">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/4-3.jpg" alt="9" class="rounded-xl">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/3-6.jpg" alt="10" class="rounded-xl">
            </div>

            <div class="hidden md:flex flex-col gap-4">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/1-3.jpg" alt="1" class="rounded-xl">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/Pergole1.jpg" alt="2" class="rounded-xl">
            </div>
            <div class="hidden md:flex flex-col gap-4">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/Pergole2.png" alt="1" class="rounded-xl">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/3-6.jpg" alt="2" class="rounded-xl">
            </div>
            <div class="hidden md:flex flex-col gap-4">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/2-2.webp" alt="2" class="rounded-xl">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/11-3.jpg" alt="2" class="rounded-xl">
            </div>
            <div class="hidden md:flex flex-col gap-4">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/2-3.jpg" alt="1" class="rounded-xl">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/1-6.jpg" alt="2" class="rounded-xl">
            </div>
            <div class="hidden md:flex flex-col gap-4">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/Pergole3.jpeg" alt="1" class="rounded-xl">
                <img src="https://litracoperture.com/wp-content/uploads/2023/05/4-3.jpg" alt="2" class="rounded-xl">
            </div>
        </div>
		<!-- Images grid end -->

        <!-- "More" link start -->
        <a href="#" class="text-end">
            <p class="font-semibold text-lg group relative">
                <span>
                  <a href="https://litrausa.com/retractable-enclosures-products">Show more photos </a>
                </span>
                <i class="fa-solid fa-arrow-right"></i>
            </p>
        </a>
        <!-- "More" link end -->

        <!-- Circle start -->
        <div class="bg-neutral-300 h-64 w-64 rounded-full absolute top-0 left-0 mt-16 -z-20"></div>
        <!-- Circle end -->
	</section>
	<!-- Portfolio section end -->
</template>

<script>
export default {

}
</script>

<style>

</style>