<script>
import MainSection from '@/sections/MainSection.vue';
import AboutSection from '@/sections/AboutSection.vue';
import ServiceSection from '@/sections/ServiceSection.vue';
import PortfolioSection from '@/sections/PortfolioSection.vue';
import ContactSection from '@/sections/ContactSection.vue';

export default {
  metaInfo: {
    title: 'Retractable Roof',
    meta: [
      { name: 'description', content: 'LITRA Retractable Roof solve problems of\n' +
            'adverse weather conditions for residential and\n' +
            'commercial applications such as restaurants, rooftop\n' +
            'enclosures, hotels, sport facilities, thermal complex and\n' +
            'more.' },
    ],
  },
  components: {
    MainSection,
    AboutSection,
    ServiceSection,
    PortfolioSection,
    ContactSection,
  }
}
</script>

<template>
  <navbar-element></navbar-element>
	<!-- Main section start -->
	<main-section></main-section>
	<!-- Main section end -->

	<!-- About section start -->
	<about-section></about-section>
	<!-- About section end -->

	<!-- Services section start -->
	<service-section></service-section>
	<!-- Services section end -->

	<!-- Portfolio section start -->
	<portfolio-section></portfolio-section>
	<!-- Portfolio section end -->


	<!-- Contact section start -->
	<contact-section></contact-section>
	<!-- Contact section end -->

</template>

<style scoped>


</style>