<template>
    <!-- Services section start -->
	<section id="services-section" class="px-12 lg:px-32 py-16 relative overflow-hidden">
        <!-- Text wrapper start -->
        <div class="flex gap-8 flex-col md:flex-row">
            <!-- Main text start -->
            <h1 class="uppercase text-5xl mb-4 font-semibold ">
                OUR SERVICES
            </h1>
            <p class="capitalize xl:w-1/2 text-stone-500 mb-8">
              Our cutting-edge Retractable <a class="text-stone-600 font-bold" href=" https://litrausa.com/retractable-roof-systems">Roof Systems</a>
              solutions
              allow you to enjoy your rooftop, penthouse, patio,

              terrace, or garden all year-round, Whatever the Weather
              conditions. With our <a class="text-stone-600 font-bold" href=" https://litrausa.com/retractable-roof-systems">motorized enclosures</a>
              , you can make the
              most of your outdoor living area and enjoy the
              magnificence of the outdoor feelings to the fullest. Select
              the best Retractable Roof for you from our broad
              products line and different versions of shape and design.
              Maximize Your Outdoor Space with one of The Top Ten
              Retractable Roofs in the World.
              We are here to help: If you have any specific questions or
              if there&#39;s anything else you&#39;d like to know, feel free to
              ask!
              Contact Us Now in
              order to Get a FREE Estimate.
            </p>
          <a href="#" class="text-end">
            <p class="font-semibold text-lg group relative">
              <router-link to="/contact">
                <span>Contact Us </span>
              </router-link>
              <i class="fa-solid fa-arrow-right"></i>
            </p>
          </a>
            <!-- Main text end -->
        </div>
        <!-- Text wrapper end -->

        <!-- Services start -->
<!--        <div class="flex flex-col md:flex-row md:gap-8">-->
            <!-- Wedding service start -->
<!--            <div class="flex flex-row md:flex-col justify-between md:justify-start items-center md:items-start my-8 md:w-1/3">-->
<!--                <img src="@/assets/services/wedding.png" alt="Wedding" class="-z-50 md:mb-4">-->
<!--                <div class="w-2/3 text-right md:text-left md:w-full">-->
<!--                    <h1 class="uppercase text-2xl font-semibold">-->
<!--                        Wedding-->
<!--                    </h1>-->
<!--                    <p class="capitalize text-stone-500">-->
<!--                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Earum, neque?-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->
            <!-- Wedding service end -->

            <!-- Wedding service start -->
<!--            <div class="flex flex-row md:flex-col justify-between items-center md:items-start my-8 md:w-1/3">-->
<!--                <div class="w-2/3 text-left md:w-full md:order-2">-->
<!--                    <h1 class="uppercase text-2xl font-semibold">-->
<!--                        Lifestyle-->
<!--                    </h1>-->
<!--                    <p class="capitalize text-stone-500">-->
<!--                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet, ratione!-->
<!--                    </p>-->
<!--                </div>-->
<!--                <img src="@/assets/services/lifestyle.png" alt="Lifestyle" class="-z-50 md:mb-4 md:order-1">-->
<!--            </div>-->
            <!-- Wedding service end -->

            <!-- Wedding service start -->
<!--            <div class="flex flex-row md:flex-col justify-between items-center md:items-start my-8 md:w-1/3">-->
<!--                <div class="w-1/3">-->
<!--                    <img src="@/assets/services/moments.png" alt="Moments" class="-z-50 md:mb-4">-->
<!--                </div>-->
<!--                <div class="w-2/3 text-right md:text-left md:w-full">-->
<!--                    <h1 class="uppercase text-2xl font-semibold">-->
<!--                        Moments-->
<!--                    </h1>-->
<!--                    <p class="capitalize text-stone-500">-->
<!--                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem, molestias.-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->
            <!-- Wedding service end -->
<!--        </div>-->
        <!-- Services end -->

        <!-- Circle start -->
        <div class="bg-neutral-300 h-64 w-64 rounded-full absolute top-0 -right-20 mt-16 -z-20"></div>
        <!-- Circle end -->
	</section>
	<!-- Services section end -->
</template>

<script>
export default {

}
</script>

<style>

</style>