<script>
export default {
  data() {
    return {
      showFullContent: false,
    };
  },
  methods: {
    scrollToAboutSection() {
      const aboutSection = document.getElementById('about-section');
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: 'smooth' });
      }
    },
    toggleContent() {
      this.showFullContent = !this.showFullContent;
    },
  },
};
</script>

<template>
  <!-- Main section start -->
  <section class="h-screen px-12 lg:px-32 py-32 mb-8">
    <div class="w-full md:w-1/2 h-full flex flex-col md:justify-center ">
      <!-- Main text start -->
      <h1 class="uppercase text-5xl xl:text-7xl font-semibold ">
        Retractable Roof LITRA
      </h1>
      <br>
      <h2 class="uppercase text-xl xl:text-2xl font-semibold">
        LITRA Retractable Roof Solve Problems of Adverse
        Climate
      </h2>
      <br>
      <p class="capitalize xl:w-1/2 text-stone-500">
        We would like to share The Best Information about LITRA
        Retractable Roof.
      </p>
      <br>
      <div>
        <button @click="toggleContent" class="btn animate-btn">
          {{ showFullContent ? 'Read Less' : 'Read More' }}
        </button>

        <div :class="{ 'read-more-content': true, 'expanded': showFullContent }">
          <p>
            These solutions will provide a versatile and adaptable outdoor living space,
            allowing individuals and businesses to make the most of their outdoor areas regardless of the weather conditions.
          </p>
          <p>
            The ability to retract the Retractable Roof and Enclosures offers flexibility, making spaces usable all year-round,
            helping the outdoor hospitality industry to improve their services and extend the working season.
          </p>
        </div>
      </div>
      <!-- Main text end -->

      <!-- Social links start -->
      <div class="flex mt-3 gap-8">
        <a href="#">
          <i class="fa-brands fa-instagram socialIcon"></i>
        </a>
        <a href="#">
          <i class="fa-brands fa-linkedin socialIcon"></i>
        </a>
        <a href="#">
          <i class="fa-brands fa-facebook socialIcon"></i>
        </a>
        <a href="#">
          <i class="fa-brands fa-dribbble socialIcon"></i>
        </a>
        <a href="#">
          <i class="fa-brands fa-pinterest socialIcon"></i>
        </a>
      </div>
      <!-- Social links end -->
    </div>

    <!-- Photographer image start -->
    <div class="absolute bottom-0 right-0 overflow-hidden h-2/5 sm:h-1/4 md:h-4/6 lg:h-4/6 -z-50">
      <img src="https://litracoperture.com/wp-content/uploads/2023/05/4.png" alt="img" class="h-full">
    </div>

    <!-- Photographer image end -->

    <!-- "More" button start -->
    <div class="w-full h-20 flex justify-start md:justify-center items-center">
      <a href="#about-section" @click="scrollToAboutSection">
        <i
            class="fa-solid fa-arrow-down animate-arrow transition text-stone-100 text-xl bg-stone-700 rounded-full shadow p-4 hover:-translate-y-2 hover:shadow-xl"
        ></i>
      </a>
    </div>
    <!-- "More" button end -->

  </section>
  <!-- Main section end -->
</template>

<style scoped>
.read-more-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease; /* Adjust the duration as needed */
}

.read-more-content.expanded {
  max-height: 1000px; /* Adjust to a suitable maximum height */
}

@keyframes arrowAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px); /* Adjust the vertical distance */
  }
}

.animate-arrow {
  animation: arrowAnimation 2s infinite; /* Adjust the duration as needed */
}

@keyframes buttonAnimation {
  0%, 100% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(2px); /* Adjust the vertical distance */
  }
}

.animate-btn {
  animation: buttonAnimation 2s infinite; /* Adjust the duration as needed */
}
</style>