<script>

import {computed, reactive} from "vue";
import {useHead} from "@vueuse/head";

export default {
  setup () {
    const siteData = reactive({
      title: 'Retractable Roof Systems Contacts',
      description: 'Contact LITRA Retractable Roof Systems For\n' +
          'help in case of outdoor climate elements problems. We\n' +
          'will provide the best Retractable Roof Systems for your\n' +
          'application.',
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'descritpion',
          content: computed(() => siteData.description),
        }
      ]
    })
  },
}
</script>

<template>
  <div class="container px-6 py-12 mx-auto">
    <div class="mt-32 max-w-screen-xl w-full">
      <h1 class="text-3xl font-bold mb-4">Retractable Roof Systems LITRA Contacts</h1>
      <h2 class="text-2xl font-bold mb-2">LITRA Retractable Roof Systems Offer Always Climate Control</h2>
      <p>
        LITRA transforms outdoor spaces into all year-round havens with its advanced
        <a href="https://litrausa.com/retractable-roof-systems" class="text-blue-500 underline">Motorized Retractable Roof Systems</a>. This operable roof structure protects outdoor hospitality businesses and their clients from the weather elements,
        allowing them to fully embrace the outdoors without compromise.
      </p>
      <p>
        By eliminating the constraints imposed by weather, <a href="https://litrausa.com" class="text-blue-500 underline">LITRA USA</a> and <a href="https://litragroup.com" class="text-blue-500 underline">LITRA Group</a> Retractable Roof Systems empower businesses to maximize their outdoor sitting capacity and create unforgettable outdoor moments that before were unavailable.
      </p>
    </div>
    <!-- Container for demo purpose -->
    <section class="bg-white">
      <div class="container px-6 py-12 mx-auto">
        <div>
          <h1 class="mt-2 text-2xl font-semibold text-gray-800 md:text-3xl">Chat to our friendly team</h1>
          <p class="mt-3 text-gray-500">We’d love to hear from you. Please fill out this form or shoot us an email.</p>
        </div>
        <div class="grid grid-rows-1 gap-12 mt-10 lg:grid-rows-1">
          <div class="grid grid-cols-1 gap-12 md:grid-cols-3">
            <div>
          <span class="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
            </svg>
          </span>
              <h2 class="mt-4 text-base font-medium text-gray-800">Email</h2>
              <p class="mt-2 text-sm text-gray-500">Our friendly team is here to help.</p>
              <p class="mt-2 text-sm text-blue-500">info@litrausa.com</p>
            </div>
            <div>
          <span class="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
            </svg>
          </span>
              <h2 class="mt-4 text-base font-medium text-gray-800">Office</h2>
              <p class="mt-2 text-sm text-gray-500">Come say hello at our office HQ.</p>
              <p class="mt-2 text-sm text-blue-500">L.I.TRA. USA 1614 Union Valley Rd, West Milford New Jersey, 07480</p>
            </div>
            <div>
          <span class="inline-block p-3 text-blue-500 rounded-full bg-blue-100/80">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
            </svg>
          </span>
              <h2 class="mt-4 text-base font-medium text-gray-800">Phone</h2>
              <p class="mt-2 text-sm text-gray-500">Mon-Fri from 8am to 5pm.</p>
              <p class="mt-2 text-sm text-blue-500">+1 (973) 5190 101</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Container for demo purpose -->
  </div>
</template>

<style scoped>
.about-us {
  margin-top: 100px;
}
</style>