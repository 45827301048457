<template>
  <!-- About section start -->
  <section id="about-section" class="px-12 lg:px-32 py-16 border-t border-stone-500 relative">
    <!-- Main text start -->
    <h1 class="uppercase text-5xl mb-4 font-semibold ">
      ABOUT US
    </h1>
    <p class="capitalize xl:w-1/2 text-stone-500 mb-8">
      LITRA Motorized Retractable Roof systems are indeed a
      valuable asset for various amenities, such as restaurants,
      hotels, rooftops, bars/pubs, and banquet halls. Our
      Retractable Roof is available in New Jersey, New York,
      Pennsylvania, Connecticut, Florida, Georgia and for big
      projects all over USA, Canada, Europe, United Kingdom,
      Australia. You can read more about our retractable roof
      in our about us
    </p>
    <!-- Main text end -->

    <!-- "More" link start -->
    <a href="#" class="text-end">
      <p class="font-semibold text-lg group relative">
        <router-link to="/about">
          <span>Read more </span>
        </router-link>
        <i class="fa-solid fa-arrow-right"></i>
      </p>
    </a>
    <!-- "More" link end -->

    <!-- Circle start -->
    <div class="bg-neutral-200 h-44 w-44 md:h-52 md:w-52 rounded-full absolute top-0 -left-20 mt-16 -z-20"></div>
    <!-- Circle end -->
  </section>
  <!-- About section end -->
</template>

<script>
export default {}
</script>

<style>

</style>