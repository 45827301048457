<script>

import {computed, reactive} from "vue";
import {useHead} from "@vueuse/head";

export default {
  setup () {
    const siteData = reactive({
      title: 'Retractable Roofs',
      description: 'About our Retractable Roofs we can say\n' +
          'that are custom made for your specific need. LITRA\n' +
          'Retractable Roofs help customers to solve problems of\n' +
          'hard weather conditions for outdoor restaurant, rooftop\n' +
          'bars enclosures, hotels and more.',
    })
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'descritpion',
          content: computed(() => siteData.description),
        }
      ]
    })
  },
}
</script>

<template>
  <div class="container mx-auto p-4">
      <div class="about-us mb-8">
        <h1 class="text-3xl font-bold mb-4">Retractable Roofs LITRA</h1>
        <p class="text-lg mb-4">
          About our Retractable Roofs we can say that are custom made for your specific need. LITRA Retractable Roofs help customers to solve problems of hard weather conditions for outdoor restaurant, rooftop bars enclosures, hotels and more.
        </p>
        <h2 class="text-2xl font-bold mb-2">LITRA Retractable Roofs Climate Control Retractable Enclosures</h2>
      </div>

      <div class="mb-8">
        <h2 class="text-2xl font-bold mb-4">We would like to provide the best features about LITRA USA</h2>
        <p>
          Retractable Roofs, a part of the LITRA Group, and our specialization in the manufacture of Motorized Retractable Roof Systems, Retractable Enclosures, Pool Enclosures.
        </p>
        <p class="mt-4">
          <strong>Company Overview:</strong> Retractable Roofs by LITRA is a part of LITRA Group. Globally recognized company specializing in the manufacture of Motorized Retractable Roofs.
        </p>
        <!-- ... (Continue with the rest of the content) -->
      </div>

      <div class="mb-8">
        <h2 class="text-2xl font-bold mb-4">Applications and Locations:</h2>
        <p>
          LITRA Retractable Roofs find applications in various states, including New York, New Jersey, Connecticut, Pennsylvania, and all over the USA and Canada. Used in restaurants, hotels, sports complexes, bars, pubs, stadiums, shopping malls, and other commercial or residential spaces.
        </p>
      </div>

      <div class="mb-8">
        <h2 class="text-2xl font-bold mb-4">Value Proposition:</h2>
        <p>
          Mission is centered on problem-solving of adverse climate conditions and delivering value to customers. Pledge to excellence in product design and production.
        </p>
      </div>

      <div class="mb-8">
        <h2 class="text-2xl font-bold mb-4">Consultation:</h2>
        <p>
          We Invite our potential customers to explore the Retractable Roofs products and Ask Now For a Free Consultation.
        </p>
      </div>
    </div>
</template>

<style scoped>
.about-us {
  margin-top: 100px;
}
</style>