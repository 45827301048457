<template>
  <!-- Wrapper for the entire page -->
  <div class="wrapper">

    <!-- Your page content goes here -->

    <!-- Footer start -->
    <div class="footer bg-stone-700 w-full flex justify-center items-center md:justify-evenly flex-col md:flex-row gap-8 p-8">
      <!-- Overview start -->
      <div class="text-stone-100 text-center">
        <p class="tracking-widest font-semibold text-stone-100">RETRACTABLE</p>
        <p class="tracking-widest font-light text-stone-100">ROOF</p>
      </div>
      <!-- Overview end -->

      <!-- Links start -->
      <div class="text-stone-100 flex justify-center items-center gap-8">
        <a href="#">
          <i class="fa-brands fa-instagram socialIcon"></i>
        </a>
        <a href="#">
          <i class="fa-brands fa-linkedin socialIcon"></i>
        </a>
        <a href="#">
          <i class="fa-brands fa-facebook socialIcon"></i>
        </a>
        <a href="#">
          <i class="fa-brands fa-dribbble socialIcon"></i>
        </a>
        <a href="#">
          <i class="fa-brands fa-pinterest socialIcon"></i>
        </a>
      </div>
      <!-- Links end -->
    </div>
    <!-- Footer end -->
  </div>
</template>

<style>
html, body {
  height: 100%;
  margin: 0;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.footer {
  margin-top: auto; /* Push the footer to the bottom */
}
</style>